export function nonNull<T>(value: T, message: string): NonNullable<T> {
	if (value === null || value === undefined) {
		throw new Error(message)
	} else {
		return value!
	}
}

export function assert(value: boolean, message: string): void {
	if (!value) throw new Error(message)
}

function requireStringEnvVar(name: string) {
	return nonNull(process.env[name], `Environment variable ${name} is not defined.`)
}

function requireBooleanEnvVar(name: string) {
	const value = requireStringEnvVar(name)
	assert(['0', '1'].includes(value), `Environment variable ${name} needs to be boolean (0 or 1).`)
	return value === '1'
}

export const config = {
	sr: {
		backendUrl: requireStringEnvVar('REACT_APP_BACKEND_URL') + 'api/',
		backendUrlV2: requireStringEnvVar('REACT_APP_BACKEND_URL') + 'api/v2/',
		backendUrlV3: requireStringEnvVar('REACT_APP_BACKEND_URL') + 'api/v3/',
		adminViewerUrl: requireStringEnvVar('REACT_APP_ADMIN_VIEWER_URL'),
		reactViewerUrl: requireStringEnvVar('REACT_APP_REACT_VIEWER_URL'),
		aws_cognito_region: requireStringEnvVar('REACT_APP_COGNITO_REGION'),
		aws_user_pools_id: requireStringEnvVar('REACT_APP_USER_POOL_ID'),
		aws_user_pools_web_client_id: requireStringEnvVar('REACT_APP_USER_POOLS_WEB_CLIENT_ID'),
		activate_sentry: requireBooleanEnvVar('REACT_APP_ACTIVATE_SENTRY'),
		companyName: 'Laser Control',
	},
}

export const amplifyConfig = {
	aws_project_region: 'eu-west-1',
	aws_cognito_region: config.sr.aws_cognito_region,
	aws_user_pools_id: config.sr.aws_user_pools_id,
	aws_user_pools_web_client_id: config.sr.aws_user_pools_web_client_id,
}
